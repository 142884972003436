import types from '../types.js'

export const pushOrgPermissions = (firestore, orgId, newOrgPermissions) => (dispatch) => {
  firestore.collection('LCDS')
      .doc('Something')
      .collection('Organizations')
      .doc(orgId)
      .update({
        Permissions: {
          ...newOrgPermissions,
        },
      })
      .then((res) => {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            permissionSet: true,
          },
        })
      })
      .catch((err) => console.log('error', err))
}
