import types from '../types'
import firebase from 'firebase/app';
import 'firebase/database';

export const postPersonImage = (
    originalImageName = '',
    imageFile,
    storage,
    database,
    personType, // 'Patients' or 'Physicians'
) => (dispatch) => {
  const uuidv4 = require('uuid/v4');
  const newImageName = uuidv4()
  const storageRef = storage.ref(personType)
  storageRef.child(newImageName).put(imageFile)
      .then((snapshot) =>
        snapshot.ref.getDownloadURL().then((personImageUrl) => {
          // if (personType === 'Physicians') {
          //   const infoURI =
          //     'Dermatology/Physicians/' +
          //     firebase.auth().currentUser.uid +
          //     '/Info'
          //   database.ref(infoURI).update(
          //       {
          //         ImageRef: payloadDownloadUrl,
          //         ImageName: newImageName,
          //       },
          //   )
          //   if (originalImageName !== '') {
          //     storageRef.child(originalImageName).delete()
          //   }
          // }
          return personImageUrl
        }))
      .then((personImageUrl) => {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            personImageUrl,
            personImageName: newImageName,
          },
        })
      })
}