import firebase from 'firebase/app'
import types from '../types.js'

export const refreshSubData = (firestore, orgId) => (dispatch) => {
  firestore.collection('LCDS') // Fetch userData
      .doc('Something')
      .collection('Organizations')
      .doc(orgId)
      .get()
      .then((res) => {
        const orgData = res.data()
        const signedIn = orgData.Status !== 'created'
        if (!signedIn) {
          alert('Please wait while we approve your account')
        }

        if (orgData["Sub"] && orgData["Sub"]["ID"]) {
          if (orgData["Sub"]["ID"] === "Promo") {
            let checkTime = orgData["Sub"]["Expires"]
            let nowTime = Date.now()
            if (nowTime < checkTime) {
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  subscriptionData: {
                    status: "active",
                    subPlan: "Promo",
                    expires: checkTime,
                    amount: 100,
                    cusID: "None",
                    dig4: "****",
                    brand: "No",
                    type: ""
                  },
                },
              })
            } else {
              alert("Your promo trial has finished.")
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  orgData: orgData,
                  signedIn: signedIn,
                  subscriptionData: {
                    status: "Expired",
                    subPlan: "Promo",
                    expires: checkTime,
                    amount: 100,
                    cusID: "None",
                    dig4: "****",
                    brand: "No",
                    type: ""
                  },
                },
              })
            }
          } else {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify(
                {"OrgId": orgId},
            );
            const requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow',
            };

            fetch(process.env.REACT_APP_URL + 'stripeGetSubscription', requestOptions)
                .then(response => {
                  return response.json()
                })
                .then(res => {
                  dispatch({
                    type: types.STORE_PAYLOAD,
                    payload: {
                      orgData: orgData,
                      signedIn: signedIn,
                      subscriptionData: {
                        status: res["status"],
                        subPlan: res["plan"]["id"],
                        subID: res["id"],
                        cusID: orgData["Stripe"]["ID"],
                        amount: res["items"]["data"][0]["plan"]["amount"],
                        interval: res["items"]["data"][0]["plan"]["interval"],
                        start: res["current_period_start"],
                        end: res["current_period_end"],
                        dig4: res["card"]["last4"],
                        brand: res["card"]["brand"],
                        type: res["card"]["funding"],
                      },
                    },
                  })
                })
                .catch((error) => {
                  alert("There was an error getting your subscription info", error)
                  dispatch({
                    type: types.STORE_PAYLOAD,
                    payload: {
                      orgData: orgData,
                      signedIn: signedIn,
                      subscriptionData: {
                        status: "error",
                        subPlan: "error",
                        subID: "error",
                        cusID: "error"
                      },
                    },
                  })
                })
          }
        } else {
          alert("Get started by choosing a subscription plan.")
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              orgData: orgData,
              signedIn: signedIn,
              subscriptionData: {
                status: "None",
                subPlan: "None",
                cusID: "None",
                amount: 0,
                dig4: "****",
                brand: "No",
                type: "",
              },
            },
          })
        }
      })
}

