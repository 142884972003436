import types from '../types.js'

export const fetchOrgData = (firestore, orgId) => (dispatch) => {
  firestore.collection('LCDS') // Fetch userData
      .doc('Something')
      .collection('Organizations')
      .doc(orgId)
      .onSnapshot((res) => {
        const orgData = res.data()
        const signedIn = orgData.Status !== 'created'
        if (!signedIn) {
          alert('Please wait while we approve your account')
        }

        if (orgData['Sub'] && orgData['Sub']['ID']) {
          if (orgData['Sub']['ID'] === 'Promo') {
            const usageDataToSend = {}
            usageDataToSend['currentUsage'] = 0
            usageDataToSend['maxUsage'] = 100
            usageDataToSend['usageData'] = []
            usageDataToSend['isFull'] = false
            const checkTime = orgData['Sub']['Expires']
            const nowTime = Date.now()
            const today = new Date()
            const year = today.getFullYear().toString()
            const month = (today.getMonth() + 1).toString()
            firestore.collection(`LCDS`)
                .doc('Something')
                .collection('Organizations')
                .doc(orgId)
                .collection('PatientUsage')
                .doc(year + '-' + month).get().then((usageData) => {
                  if (usageData.exists) {
                    usageDataToSend['currentUsage'] = usageData.data()['Patients'].length
                    usageDataToSend['usageData'] = usageData.data()
                  }
                  if (usageDataToSend['currentUsage'] >= usageDataToSend['maxUsage']) {
                    usageDataToSend['isFull'] = true
                  }
                })
            if (nowTime < checkTime) {
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  isLoading: false,
                  orgData: orgData,
                  signedIn: signedIn,
                  subscriptionData: {
                    status: 'active',
                    subPlan: 'Promo',
                    expires: checkTime,
                    amount: 100,
                    cusID: 'None',
                    dig4: '****',
                    brand: 'No',
                    type: '',
                  },
                  usageData: usageDataToSend,
                },
              })
            } else {
              alert('Your promo trial has finished.')
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  isLoading: false,
                  orgData: orgData,
                  signedIn: signedIn,
                  subscriptionData: {
                    status: 'Expired',
                    subPlan: 'Promo',
                    expires: checkTime,
                    amount: 100,
                    cusID: 'None',
                    dig4: '****',
                    brand: 'No',
                    type: '',
                  },
                  usageData: usageDataToSend,
                },
              })
            }
          } else {
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');

            const raw = JSON.stringify(
                {
                  'OrgId': orgId,
                },
            );
            const requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow',
            };
            fetch(process.env.REACT_APP_URL + 'stripeGetSubscription', requestOptions)
                .then((response) => {
                  return response.json()
                })
                .then((res) => {
                  dispatch({
                    type: types.STORE_PAYLOAD,
                    payload: {
                      isLoading: false,
                      orgData: orgData,
                      signedIn: signedIn,
                      subscriptionData: {
                        subNum: Object.keys(orgData['Permissions']).length,
                        status: res['status'],
                        subPlan: res['items']['data'][0]['plan']['id'],
                        subID: res['id'],
                        cusID: orgData['Stripe']['ID'],
                        amount: res['items']['data'][0]['plan']['amount'],
                        interval: res['items']['data'][0]['plan']['interval'],
                        start: res['current_period_start'],
                        end: res['current_period_end'],
                        dig4: res['card']['last4'],
                        brand: res['card']['brand'],
                        type: res['card']['funding'],
                      },
                      usageData: {
                        currentUsage: res['currentUsage'],
                        maxUsage: res['maxUsage'],
                        isFull: res['isFull'],
                        usageData: ['usageData'],
                      },
                    },
                  })
                })
                .catch((error) => {
                  // console.log("Fetch sub error:", error)
                  dispatch({
                    type: types.STORE_PAYLOAD,
                    payload: {
                      isLoading: false,
                      userMessage: 'There was an error getting your subscription info. Please contact Lazarus for support',
                      notificationType: 2,
                      notificationIcon: 'warning',
                    },
                  })
                  dispatch({
                    type: types.STORE_PAYLOAD,
                    payload: {
                      isLoading: false,
                      orgData: orgData,
                      signedIn: signedIn,
                      subscriptionData: {
                        status: 'error',
                        subPlan: 'error',
                        subID: 'error',
                        cusID: 'error',
                      },
                    },
                  })
                })
          }
        } else {
          // dispatch({ // Clear notification
          //   type: types.STORE_PAYLOAD,
          //   payload: {
          //     userMessage: 'Get started by choosing a subscription plan in settings',
          //     notificationType: 2,
          //     notificationIcon: 'check',
          //   },
          // })
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              orgData: orgData,
              signedIn: signedIn,
              isLoading: false,
              subscriptionData: {
                status: 'None',
                subPlan: 'None',
                cusID: 'None',
                amount: 0,
                dig4: '****',
                brand: 'No',
                type: '',
              },
              usageData: {
                currentUsage: 0,
                maxUsage: 0,
                isFull: true,
                usageData: ['usageData'],
              },
            },
          })
        }
      })
}

