import firebase from 'firebase/app'
import types from '../types.js'

export const fetchOrgMemberData = (firestore, orgMemberIds) => (dispatch) => {
  const orgPhysiciansData = {}
  orgMemberIds.forEach((orgMemberId) => {
    if (orgMemberId !== 'UID') {
      firestore.collection('LCDS') // Fetch userData
          .doc('Something')
          .collection('Physicians')
          .doc(orgMemberId)
          .get()
          .then((res) => {
            orgPhysiciansData[orgMemberId] = res.data()
            if (Object.keys(orgPhysiciansData).length === orgMemberIds.length) {
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  orgPhysiciansData,
                  isLoading: false,
                },
              })
            }
          })
    }
  })
}
