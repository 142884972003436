import types from '../types'
import firebase from 'firebase/app'
import 'firebase/database'
export const postTest = (firestore, storage, newTestData, currentPatient, orgId) => (dispatch) => {
  dispatch({
    type: types.STORE_PAYLOAD,
    payload: {
      isTestUploaded: false,
      isLoading: true,
    },
  })
  const patientId = currentPatient.ID
  const newDate = new Date()
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const hour = newDate.getHours();
  const min = newDate.getMinutes();
  const sec = newDate.getSeconds();
  // const dateTaken =
  //   `${year}/${month<10?`0${month}`:
  //   `${month}`}/${date<10?`0${date}`:
  //   `${date}`} ${hour<10?`0${hour}`:
  //   `${hour}`}:${min<10?`0${min}`:
  //   `${min}`}:${sec<10?`0${sec}`:
  //   `${sec}`}`
  const dateTaken = Date.now()
    // this.props.toggleLoader(true)
  const uuidv4 = require('uuid/v4');
  const storageRef = storage.ref('Tests')
  const testName = uuidv4()
  storageRef.child(testName).put(newTestData.croppedImageFile).then((snapshot) =>
    snapshot.ref.getDownloadURL().then((downloadURL) => {
      firestore
          .collection('LCDS')
          .doc('Something')
          .collection('Patients')
          .doc(patientId)
          .collection('DermatologyTests')
          .doc(testName)
          .set({
            DateTaken: dateTaken,
            ImageRef: downloadURL,
            MoleLocation: newTestData.moleLocation || 'N/A',
            MoleCoordinates: newTestData.moleCoordinates,
            Front: newTestData.front,
            Type: 'Local',
            Flag: false,
            Notes: null,
          })
      setTimeout(function() {
        (async () => {
          /*  patient_id and patient are the same but used to be different
              One was for the patient in the org doc, the other in the
              Patients collection. Dermato api requires both
          */
          const dataToSend = JSON.stringify({
            patient_id: patientId,
            org_id: orgId,
            patient: patientId,
            ids: [testName],
          })
          const rawResponse = await fetch(process.env.REACT_APP_URL + 'dermato', {
            method: 'POST',
            body: dataToSend,
          });
          const content = await rawResponse.json();
          // const patientUsageRef =
          //   firebase
          //       .collection('LCDS')
          //       .doc('Something')
          //       .collection('Organizations')
          //       .doc(orgId)
          //       .collection('PatientUsage')

          if (content['message'] == 'Success!') {
            firestore
                .collection('LCDS')
                .doc('Something')
                .collection('Organizations')
                .doc(orgId)
                .collection('Patients')
                .doc(patientId)
                .update({
                  LastUpdate: dateTaken,
                })
                .then(() => {
                  dispatch({
                    type: types.STORE_PAYLOAD,
                    payload: {
                      isTestUploaded: true,
                      isLoading: false,
                      userMessage: 'Test has been successfully uploaded',
                      notificationType: 2,
                      notificationIcon: 'check',
                    },
                  })
                })
            firestore
                .collection('LCDS')
                .doc('Something')
                .collection('Organizations')
                .doc(orgId)
                .collection('PatientUsage')
                .doc(year + '-' + month)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    const patDataToUse = doc.data()
                    if (!patDataToUse['Patients'].includes(patientId)) {
                      patDataToUse['Patients'].push(patientId)
                      firestore
                          .collection('LCDS')
                          .doc('Something')
                          .collection('Organizations')
                          .doc(orgId)
                          .collection('PatientUsage')
                          .doc(year + '-' + month)
                          .update(patDataToUse)
                    }
                  } else {
                    // doc.data() will be undefined in this case
                    firestore
                        .collection('LCDS')
                        .doc('Something')
                        .collection('Organizations')
                        .doc(orgId)
                        .collection('PatientUsage')
                        .doc(year + '-' + month)
                        .set({
                          'Patients': [patientId],
                        })
                  }
                })
            firestore
                .collection('LCDS')
                .doc('Something')
                .collection('Organizations')
                .doc(orgId)
                .collection('PatientUsage')
                .doc(year.toString())
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    const patDataToUse = doc.data()
                    if (!patDataToUse['Patients'].includes(patientId)) {
                      patDataToUse['Patients'].push(patientId)
                      firestore
                          .collection('LCDS')
                          .doc('Something')
                          .collection('Organizations')
                          .doc(orgId)
                          .collection('PatientUsage')
                          .doc(year.toString())
                          .update(patDataToUse)
                    }
                  } else {
                    // doc.data() will be undefined in this case
                    firestore
                        .collection('LCDS')
                        .doc('Something')
                        .collection('Organizations')
                        .doc(orgId)
                        .collection('PatientUsage')
                        .doc(year.toString())
                        .set({
                          'Patients': [patientId],
                        })
                  }
                })
          } else { // Content message !== success
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isTestUploaded: true,
                isLoading: false,
                userMessage: 'There was an error submitting your test. Please try again and contact Lazarus if problem persists.',
                notificationType: 3,
                notificationIcon: 'warning',
              },
            })
            // console.log(content['message'])
          }
        })()
      }, 2500)
    }),
  )
}
