import types from '../types'

const initialState = {
  userData: {}, // All Firestore user data,
  signedIn: false,
  path: 'home', // Path for topNav
  newPatient: {}, // contents of NewPatientForm + message if mProc + *imageUrl
  isMprocRequest: false, // AddPatient used for both local/m-proc testing,
  pagesDict: { // Default pages state
    dashboard: false,
    mproc: false,
    documentation: false,
    settings: false,
  },
  pages: {},
  patientList: [], // all patients "SharedWith" user
  patientsObj: {}, // patients from patientList in "ID: patientList[i]"" format
  personImageUrl: null, // download url for user or patient image
  personImageName: null,
  orgData: null, // Contains permissions for org physicians
  /*
    {
      UID: {
        Permissions1: boolean,
        ...
      }
    }
  */
  orgPhysiciansData: null, // Contains Name/Email/Auth for org physicians
  /*
    {
      UID: {
        Name,
        Email,
        Auth,
      }
    }
  */
  newTestData: {}, // Contains image and metaData for a new test
  patientsData: {}, // Contains all tests (and patient data)
  /*
    {
      patientUID: [
        Type, // Local/M-Proc
        Results,
        Confidence,
        DateTaken,
        ImageRef,
        Flag, // boolean
        Notes, 'physician notes',
        Reasoning: [ // Differential
          {
            Confidence,
            Name,
          },
        ]
      ]
    }
  */

  /* Used to send patient email after creating
     patient in m-proc "new patient"
  */
  formattedNewPatient: null,

  /*  currentPatient, currentTestSet, and currentTest
      will dictate path in TopNav
  */
  currentPatient: null,
  currentTestSet: null,
  currentTest: null,

  isAIActive: true,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case types.STORE_VALUE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      }
    case types.STORE_PAYLOAD:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
