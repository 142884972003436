import types from '../types'


export const postPromoCode = (firestore, promoCode, orgID) => (dispatch) => {
  firestore
      .collection('LCDS')
      .doc('Something')
      .collection('Organizations')
      .doc(orgID)
      .get().then((doc) => {
        if (doc.exists) {
          const dataToWorkWith = doc.data()
          if (dataToWorkWith['Promos'] && dataToWorkWith['Promos'].hasOwnProperty(promoCode)) {
            const promos = dataToWorkWith['Promos']
            if (!promos[promoCode]) {
              const today = new Date()
              const month = today.getMonth() + 1
              const date = today.getDate()
              const year = today.getFullYear()
              const dateString = `${month < 10 ? `0${month}` : `${month}`}/${date < 10 ? `0${date}` : `${date}`}/${year}`
              if (promoCode === 'LazMProc') {
                promos[promoCode] = true
                const expirDate = new Date(dateString).getTime() + 7257600000
                firestore
                    .collection('LCDS')
                    .doc('Something')
                    .collection('Organizations')
                    .doc(orgID)
                    .update({
                      Sub: {
                        Expires: expirDate,
                        Start: new Date().getTime(),
                        ID: 'Promo',
                        isActive: true,
                        isMProcFree: true,
                      },
                      Promos: promos,
                    })
                    .then(() => {
                      dispatch({
                        type: types.STORE_PAYLOAD,
                        payload: {
                          subscriptionData: {
                            status: "active",
                            subPlan: "Promo",
                            expires: expirDate,
                            cusID: "None",
                            dig4: "****",
                            brand: "No",
                            type: ""
                          },
                        },
                      })
                    })
              } else { // Codes other than 'LazMProc'
                promos[promoCode] = true
                const expirDate = new Date(dateString).getTime() + 604800000
                firestore
                    .collection('LCDS')
                    .doc('Something')
                    .collection('Organizations')
                    .doc(orgID)
                    .update({
                      Sub: {
                        Expires: expirDate,
                        Start: new Date().getTime(),
                        ID: 'Promo',
                        isActive: true,
                        isMProcFree: true,
                      },
                      Promos: promos,
                    })
                    .then(() => {
                      dispatch({
                        type: types.STORE_PAYLOAD,
                        payload: {
                          subscriptionData: {
                            status: "active",
                            subPlan: "Promo",
                            expires: expirDate,
                            cusID: "None",
                            dig4: "****",
                            brand: "No",
                            type: ""
                          },
                        },
                      })
                    })
              }
            } else {
              // dispatch({
              //   type: types.STORE_PAYLOAD,
              //   payload: {
              //     promoMessage: 'Error: Code has already been used',
              //     time: Date.now(),
              //   },
              // })
              alert('Error: Code has already been used')
            }
          } else {
            // dispatch({
            //   type: types.STORE_PAYLOAD,
            //   payload: {
            //     promoMessage: 'Error: Invalid Code Entered',
            //   },
            // })
            alert('Error: Invalid Code Entered')
          }
        } else {
        // doc.data() will be undefined in this case
          // dispatch({
          //   type: types.STORE_PAYLOAD,
          //   payload: {
          //     promoMessage: 'Error: Invalid Code Entered',
          //   },
          // })
          alert('Error: Invalid Code Entered')
        }
      })
}
