// Dependencies
import React, { useState } from 'react'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import Helpers from '../Helpers.js'

// Components
import { MProc, Button, Input } from '@anishp16/lazarus-cds'

// Styles
import '../styles/TestLink.css'
import '@anishp16/lazarus-cds/dist/index.css'

function TestLink(props) {
  const [isLinkCoppied, setIsLinkCoppied] = useState(false)
  const [isLinkSending, setIsLinkSending] = useState(false)
  const [patientNumber, setPatientNumber] = useState(null)
  const testingLink = 'https://portal.lazarusderm.com/?state='
  const copyText = testingLink + firebase.auth().currentUser.uid + '&orgId=' + props.userData.Membership.Organization

  const copyLink = () => {
    setIsLinkCoppied(true)
  }

  const sendPatientInviteToSonnet = () => {
    setIsLinkSending(true)
    if (patientNumber !== null && patientNumber.match(/^\d{10}$/)) {
      const bodyToSend = JSON.stringify({
        token: 'ZKOXJTipbgSl7Fft0m96',
        url: copyText,
        phone: patientNumber,
      })
      const requestOptions = {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        body: bodyToSend,
      };
      fetch(`https://lzr.care/ping_patient`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            setPatientNumber(null)
            setIsLinkSending(false)
            alert('Success! A text has been sent.')
          })
          .catch((error) => {
            alert('There was an error sending the text, please try again later.')
            console.log('error', error)
          });
    } else {
      alert('Please enter the number in the correct format (##########)')
      setIsLinkSending(false)
    }
  }

  return (
    <div className='test-link'>
      <div className='link-div'>
        <a>{copyText}</a>
      </div>
      <Button
        text={isLinkCoppied ? 'Link Copied' : 'Copy Link'}
        type={isLinkCoppied ? 2 : 1}
        onClick={() => Helpers.copyToClipBoard(copyText, setIsLinkCoppied)}
      />
      <div className='link-div'>
        <div>
          Send an Invite via Text
        </div>
        <Input
          disabled={false}
          label=''
          type='number'
          value={patientNumber}
          placeholder='##########'
          onChange={(e) => setPatientNumber(e.target.value)}
        />
      </div>
      <Button
        text={isLinkSending ? 'Sending Link' : 'Send Link'}
        type={isLinkSending ? 1 : 2}
        onClick={() => sendPatientInviteToSonnet(copyText, setIsLinkCoppied)}
      />

    </div>
  )
}

const mapStateToProps = (state) => ({
  userData: state.firebaseReducer.userData,
})

export default connect(
    mapStateToProps,
)(TestLink)
