import types from '../types'

export const storeValue = (name, value) => (dispatch) => {
  dispatch({
    type: types.STORE_VALUE,
    payload: {
      name,
      value,
    },
  })
}
