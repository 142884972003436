import React from 'react'
import x from '../images/x.svg'
import '../styles/FloatingActionButton.css'

function FloatingActionButton(props) {
  return (
    <div
      className={props.isX ? 'floating-action-button-x' : 'floating-action-button'}
      onClick={props.onClick}
    >
      {props.isX ? <img src={x} alt='x' /> : <div />}
    </div>
  )
}

export default FloatingActionButton
