// Dependencies
import React, {useState, useEffect, Fragment} from 'react'
import {connect} from 'react-redux'
import Helpers from '../Helpers.js'

// Components
import {
  AsideDiv, Button,
} from '@anishp16/lazarus-cds'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/TestUpload.css'

// Images
import manFront from '../images/wirePerson/manFront.jpg'
// import manBack from '../images/wirePerson/manBack.jpg'
import womanFront from '../images/wirePerson/womanFront.jpg'
// import womanBack from '../images/wirePerson/womanBack.jpg'

/* props.newTestData.step:
      0: No crop made on test image
      1: Crop is made on test image
      2: Crop is approved on test image, location selector rendered
*/

function TestUpload(props) {
  const defaultTabs = {
    cropImage: false,
    location: false,
  }
  const [tabs, setTabs] = useState({defaultTabs})

  /* Set default tab to step 1: crop image */
  useEffect(() => {
    onClickTab('cropImage')
  }, [])

  // useEffect(() => {
  //   if (props.newTestData.croppedImageUrl) {
  //     onClickTab('location')
  //   }
  // }, [props.newTestData])

  const onClickTab = (tab) => {
    setTabs({
      ...defaultTabs,
      [tab]: true,
    })
  }


  const dateTime = new Date()
  const minutes =
      dateTime.getMinutes().toString().length === 1 ?
      '0' + dateTime.getMinutes().toString() :
      dateTime.getMinutes()
  return (
    <div className='test-upload-page'>
      <main>
        <aside>
          <AsideDiv
            type={2}
            invertColor={true}
            text='Test Date'
            style={{backgroundColor: 'rgba(0, 0, 0, 0'}}
            number={Helpers.timeStampToDate(Date.now())}
          />
          <AsideDiv
            type={2}
            invertColor={true}
            text='Step 1'
            number={'Crop Image'}
            // onClick={() => onClickTab('cropImage')}
            // isSelected={tabs['cropImage']}
            /* Step 1 was crop confirm but was deprecated */
            isSelected={!props.newTestData.step || props.newTestData.step === 1}
          />
          <AsideDiv
            type={2}
            invertColor={true}
            text='Step 2'
            number={'Location'}
            // onClick={() => onClickTab('location')}
            // isSelected={tabs['location']}
            isSelected={props.newTestData.step === 2}
          />
        </aside>
        <div className='test-upload-content'>
          {tabs['cropImage'] &&
            <div className='crop-image'>
              <div className='test-upload-cropperContainer'>
                <div className='imageContainer'>
                  {props.cropper}
                </div>
              </div>
            </div>
          }
          {tabs['location'] &&
            <div>
              
            </div>
          }
        </div>
        <div className='test-upload-right'>
          {/* size-warning container also used for location data for
          location selector */}
          <div className='size-warning-container'>
            {props.isTestImageTooSmall && (!props.newTestData.step || props.newTestData.step < 2) &&
              <div className='size-warning'>
                <div className='flex justify-space-between'>
                  <span className='yellow'>&#9888;</span>
                  <span className='font-small'>
                    {`${dateTime.getHours()}: ${minutes}`}
                  </span>
                </div>
                <p>Warning: Image should be at least 480 x 480 pixels</p>
                <p>For best results, choose a photo with higher resolution</p>
              </div>
            }
            {props.newTestData &&
            props.newTestData.moleLocation &&
            props.newTestData.step === 2 && (
              <div className='location-container'>
                <p>{`Location`}</p>
                <p className='location-p'>
                  {`${props.newTestData.moleLocation}`}
                </p>
              </div>
            )}
          </div>
          {(!props.newTestData.step || props.newTestData.step < 2) &&
            <img
              src={props.currentPatient.Sex === 'female' ? womanFront : manFront}
              className='location-preview'
            />
          }
          {props.newTestData.step === 2 && props.newTestData && props.newTestData.croppedImageUrl &&
            <Fragment>
              <img
                src={props.newTestData.croppedImageUrl}
                className='location-preview'
              />
            </Fragment>
          }
        </div>
        <div className='bottom-buttons'>
          <Button
            type={2}
            width='50%'
            text={!props.newTestData.step || props.newTestData.step < 2 ? 'Cancel' : 'Back'}
            onClick={props.onClickCancel}
          />
          <Button
            width='50%'
            text={'Next'}
            onClick={props.onClickNext}
          />
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state) => ({
  newTestData: state.userReducer.newTestData,
  currentPatient: state.userReducer.currentPatient,
  isTestImageTooSmall: state.userReducer.isTestImageTooSmall,
})

export default connect(
    mapStateToProps,
    // {storeValue},
)(TestUpload)

