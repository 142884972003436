// Dependencies
import React, {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'
import ReactCrop from 'react-image-crop'
import {connect} from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import loadImage from '../../node_modules/blueimp-load-image/js'

// Actions
import {postPersonImage} from '../actions/postPersonImage.js'
import {storeValue} from '../actions/storeValue.js'
import {storePayload} from '../actions/storePayload.js'
import {postTest} from '../actions/postTest.js'

// Components
import {Loader, FloatingButton, Button} from '@anishp16/lazarus-cds'
import TestUpload from './TestUpload'
import LocationSelector from './LocationSelector'

// Styles
import 'react-image-crop/dist/ReactCrop.css'
import '../styles/ImageUpload.css'
import '@anishp16/lazarus-cds/dist/index.css'

// Images
import upload from '../images/white/upload.svg'
import add from '../images/white/Add.svg'

/*  ImageUpload renders a button that allows user to choose a photo
    on device. The button appears differently for different use cases
    (Patient/Physician/Test).
    Once an image is chosen, the cropper screen is rendered
    depending on use case.
      - Test: Upon selecting a test image, TestUpload component is rendered,
        which takes a render prop from ImageUpload
          - The render prop is either the default image cropper or
            LocationSelector component
          * Steps 0 - 2:
            0: Drag cropper
            1: Confirm crop (deprecated)
            2: Choose location
      - Person: Upon selecting a patient/physician, personCropperContainer
        (defined in this render function) is rendered
          - personCropperContainer renders cropper, buttons, and gray screen
    This component is an artifcact from a previous build. I'm so sorry...
    To Do:
      * Make ImageUpload and Location selector functional components
      * Separate different render constants into new components
      * Store 'state' in redux store
*/

class ImageUpload extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cropHasBeenAdjusted: false,
      crop: {
        unit: '%',
        height: 100,
        width: 100,
        aspect: 1 / 1,
      },

      minWidth: null,
      maxWidth: null,
      isImageSelected: false,
      imageFile: [],
      imageUrl: [],
      croppedImageFile: null,
      croppedImageUrl: null,
      isImageCropped: false,

      loading: false,
      tooSmall: false,
    }
    this.onSelectImage = this.onSelectImage.bind(this)
    this.onImageLoaded = this.onImageLoaded.bind(this)
    this.onCropComplete = this.onCropComplete.bind(this)
    this.onCropChange = this.onCropChange.bind(this)
    this.onClickCancelCrop = this.onClickCancelCrop.bind(this)
    this.onClickNext = this.onClickNext.bind(this)
  }

  onSelectImage(event) {
    loadImage(event.target.files[0], (img) => {
      if (this.props.onClick) {
        // Displays container in full screen
        this.props.onClick()
      }
      if (img.height > img.width) { // make cropper square and center
        const yPx = (img.height - img.width) / 2
        const yPercent = yPx / img.height * 100
        this.setState({
          crop: {
            ...this.state.crop,
            width: 100, // (%)
            height: null,
            y: yPercent,
            x: 0,
          },
        })
      } else {
        const xPx = (img.width - img.height) / 2
        const xPercent = xPx / img.width * 100
        this.setState({
          crop: {
            ...this.state.crop,
            width: null,
            height: 100,
            y: 0,
            x: xPercent,
          },
        })
      }
      const blobPromise = new Promise((resolve, reject) => {
        if (img.height < 480 || img.width < 480) {
          this.props.storeValue('isTestImageTooSmall', true)
          this.setState({tooSmall: true})
          this.props.storeValue('isTestImageTooSmall', true)
        } else {
          this.props.storeValue('isTestImageTooSmall', false)
          this.setState({tooSmall: false})
        }
        img.toBlob((blob) => {
          if (!blob) {
            console.error('Canvas is empty')
            return
          }
          blob.name = 'fileName'
          this.fileUrl = window.URL.createObjectURL(blob)
          resolve([this.fileUrl, blob])
        }, 'image/jpeg')
      })

      blobPromise
          .then((imageArr) => {
            this.setState({
              isImageSelected: true,
              imageUrl: imageArr[0],
              imageFile: imageArr[1],
              isImageCropped: false,
            })
          })
          .catch((error) => console.log('blob error', error))
    }, {orientation: true})
  }

  onImageLoaded(image) {
    this.imageRef = image;
  }

  onCropComplete(crop) {
    this.makeClientCrop(crop);
  }

  onCropChange(crop, percentCrop) {
    this.setState({crop: percentCrop})
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImage = await this.getCroppedImg(
          this.imageRef,
          crop,
          'newFile.jpeg',
      )
      const croppedImageUrl = croppedImage[0]
      const croppedImageFile = croppedImage[1]

      this.setState({croppedImageUrl, croppedImageFile});
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const maxWidth = 960
    const shrinkRatio = maxWidth / image.naturalWidth

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    if (image.naturalWidth > maxWidth) {
      canvas.width = Math.floor(crop.width * scaleX * shrinkRatio);
      canvas.height = Math.floor(crop.height * scaleY * shrinkRatio);
    } else {
      canvas.width = Math.floor(crop.width * scaleX);
      canvas.height = Math.floor(crop.height * scaleY);
    }
    const ctx = canvas.getContext('2d')

    /* Math for guaranteeing minimum size of 480 x 480 */
    let minWidth

    if (image.naturalWidth > maxWidth) {
      minWidth = 480 / scaleX / shrinkRatio
      ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX * shrinkRatio,
          crop.height * scaleY * shrinkRatio,
      )

    } else {
      minWidth = 480 / scaleX
      ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY,
      )
    }

    this.setState({minWidth})

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'))
        }
        blob.name = fileName
        this.fileUrl = window.URL.createObjectURL(blob)
        resolve([this.fileUrl, blob])
      }, 'image/jpeg')
    })
  }

  /* Triggered if this.props.isPatient is true (from AddPatient component) */
  onClickSubmitPersonImage(event, personType) {
    event.preventDefault()
    event.stopPropagation()
    let imageName2
    if (this.props.userData && this.props.userData.Info.ImageName !== '') {
      imageName2 = this.props.userData.Info.ImageName
    } else {
      imageName2 = ''
    }
    if (!this.state.isImageCropped) {
      this.setState({
        isImageCropped: true,
      })
    } else {
      this.props.postPersonImage(
          imageName2,
          this.state.croppedImageFile,
          this.props.storage,
          this.props.database,
          personType,
      ) // replace
      this.setState({isImageSelected: false})
    }
  }

  /* Only for tests */
  /* Crop confirmation (step 1) is skipped */
  cropImageAndProceedToLocation() {
    this.props.storeValue('newTestData', {
      ...this.props.newTestData,
      step: 1,
    })
    this.setState({
      isLocationCropperRendered: true,
    })
    this.props.storeValue('newTestData', {
      ...this.props.newTestData,
      croppedImageUrl: this.state.croppedImageUrl,
      croppedImageFile: this.state.croppedImageFile,
      step: 2,
    })
  }

  /* Only for tests */
  /* Crop confirmation (step 1) is skipped */
  onClickNext(ev) {
    ev.preventDefault()
    if (!this.state.isImageCropped) {
      this.setState({
        cropHasBeenAdjusted: false,
        isImageCropped: true,
      }, this.cropImageAndProceedToLocation())
    } else {
      this.props.postTest(
          this.props.firestore,
          this.props.storage,
          this.props.newTestData,
          this.props.currentPatient,
          this.props.userData.Membership.Organization,
      )
      this.setState({
        isImageSelected: false,
        croppedImageUrl: null,
        croppedImageFile: null,
        isLocationCropperRendered: false,
      })
      if (this.props.onClick) {
        // From Patient.js and Test.js, shrinks container to FAB
        this.props.onClick()
        this.props.closeFab()
      }
      this.props.storeValue('newTestData', {
        // ...this.props.newTestData,
        step: 0,
      })
    }
  }

  onClickSubmitTestImage(ev) {
    const newDate = new Date()
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const hour = newDate.getHours();
    const min = newDate.getMinutes();
    const sec = newDate.getSeconds();
    const dateTaken =
      `${year}/${month<10?`0${month}`:
      `${month}`}/${date<10?`0${date}`:
      `${date}`} ${hour<10?`0${hour}`:
      `${hour}`}:${min<10?`0${min}`:
      `${min}`}:${sec<10?`0${sec}`:
      `${sec}`}`

    if (!this.state.isImageCropped) {
      this.setState({
        cropHasBeenAdjusted: false,
        isImageCropped: true,
      })
    } else {
      this.setState({
        isImageSelected: false,
      })
      // this.props.toggleLoader(true)
      const uuidv4 = require('uuid/v4');
      const storageRef = this.props.storage.ref('Tests')
      const self = this
      if (this.state.croppedImageFile) {
        const testname = uuidv4()
        storageRef.child(testname).put(this.state.croppedImageFile).then((snapshot) =>
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            self.props.firestore.collection('Tests').doc(`${testname}`).set({
              DateTaken: dateTaken,
              ImageRef: downloadURL,
            })

            setTimeout(function() {
              (async () => {
                const dataToSend = JSON.stringify({
                  patient: self.props.patientId2,
                  ids: [testname],
                })
                const rawResponse = await fetch(process.env.REACT_APP_URL + 'dermato', {
                  method: 'POST',
                  body: dataToSend,
                });
                const content = await rawResponse.json();


                if (content['message'] == 'Success!') {
                  self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Patients/'+ self.props.patientId).update({
                    LastUpdate: dateTaken,
                  })
                  self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/PatientCount/' + year + '/' + month + '/Patients').once('value', (snapshot) => {
                    if (snapshot.exists()) {
                      const dataToUse = snapshot.val()
                      if (dataToUse.includes(self.props.patientId)) {
                      } else {
                        const numInput = String(dataToUse.length)
                        self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/PatientCount/' + year + '/' + month + '/Patients/' + numInput).set(self.props.patientId)
                      }
                    } else {
                      self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/PatientCount/' + year + '/' + month + '/Patients/0').set(self.props.patientId)
                    }
                  })

                  self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/PatientCount/' + year + '/Patients').once('value', (snapshot) => {
                    if (snapshot.exists()) {
                      const dataToUse = snapshot.val()
                      if (dataToUse.includes(self.props.patientId)) {
                      } else {
                        const numInput = String(dataToUse.length)
                        self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/PatientCount/' + year + '/Patients/' + numInput).set(self.props.patientId)
                      }
                    } else {
                      self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/PatientCount/' + year + '/Patients/0').set(self.props.patientId)
                    }
                  })
                  self.props.database.ref('Dermatology/Stats/' + firebase.auth().currentUser.uid + `/${year}/${month}/${date}/TestsTaken`).once('value').then(function(snapshotNum) {
                    if (snapshotNum.exists()) {
                      self.props.database.ref('Dermatology/Stats/' + firebase.auth().currentUser.uid + `/${year}/${month}/${date}/TestsTaken`).set(snapshotNum.val() + 1)
                    } else {
                      self.props.database.ref('Dermatology/Stats/' + firebase.auth().currentUser.uid + `/${year}/${month}/${date}/TestsTaken`).set(1)
                    }
                  })
                  self.setState({
                    addImage: false,
                    grayScreen: false,
                    loading: false,
                    croppedImage: [],
                  }, () => {
                    if (self.props.onClick) {
                      // From Patient.js and Test.js, shrinks container to FAB
                      self.props.onClick()
                    }
                  })
                } else {
                  self.setState({
                    grayScreen: false,
                    loading: false,
                  }, () => {
                    if (self.props.onClick) {
                      // From Patient.js and Test.js, shrinks container to FAB
                      self.props.onClick()
                    }
                  })
                  alert('There was an error submitting your tests.')
                }
              })()
            }, 2500)
          }),
        )
      } else {
        alert('There is no test to submit.')
      }
    }
  }

  onClickCancelCrop(event) {
    event.stopPropagation()
    event.preventDefault()
    this.state.isImageCropped ?
    this.setState({
      croppedImageUrl: null,
      croppedImageFile: null,
      isImageCropped: false,
      isLocationCropperRendered: false,
    }, () => {
      this.props.storeValue('newTestData', {
        ...this.props.newTestData,
        step: 1,
      })
    }) :
    this.setState({
      isLocationCropperRendered: false,
      cropHasBeenAdjusted: false,
      croppedImageUrl: null,
      croppedImageFile: null,
      imageUrl: [],
      imageFile: [],
      isImageSelected: false,
      tooSmall: false,
      crop: {
        unit: '%',
        height: 100,
        width: 100,
        aspect: 1 / 1,
      },
    }, () => {
      if (this.props.onClick) {
        // From Patient.js and Test.js, shrinks container to FAB
        this.props.onClick()
        this.props.closeFab()
      }
      this.props.storeValue('newTestData', {
        // ...this.props.newTestData,
        step: 0,
      })
    })
    document.getElementById('AddImageInput').value = null
  }

  render() {
    const clickableRender = () => {
      if (this.props.isPhysician && this.props.physicianPhoto) {
        // for settings user image
        return (
          <img
            className="settings-img"
            src={
              this.state.croppedImageUrl ?
              this.state.croppedImageUrl :
              this.props.physicianPhoto
            }
            alt="User Image"
          />
        )
      } else if (this.props.isTest) {
        // for test upload
        return (
          <FloatingButton
            image={upload}
            text='Upload'
            label='Upload image from device'
          />
        )
      } else {
        return ( // for patient image
          <img className={this.props.personImageUrl ? 'person-img' : 'plus-img'} src={this.props.personImageUrl || add} alt='+' />
        )
      }
    }

    const locationCropper = (
      <LocationSelector />
      // <div></div>
    )

    const cropper = !this.state.isImageCropped ?
      <ReactCrop
        className='elAddImageCropper'
        src={this.state.imageUrl}
        crop={this.state.crop}
        ruleOfThirds
        onImageLoaded={this.onImageLoaded}
        onComplete={this.onCropComplete}
        onChange={this.onCropChange}
        minWidth={this.state.minWidth}
        minHeight={this.state.minWidth}
        maxWidth={this.state.maxWidth}
        maxHeight={this.state.maxHeight}
        keepSelection={'true'}
      /> :
      <img className='elAddImageCropper' src={this.state.croppedImageUrl}></img>

    const personCropperContainer = this.state.isImageSelected && (
      <Fragment>
        <div
          className="gray-screen"
          onClick={() => this.setState({isImageSelected: false})}
        />
        <div className="cropperContainer">
          <div className="imageContainer">
            {cropper}
          </div>
          <div className="buttonContainer">
            <Button
              type={2}
              width='50%'
              text='Cancel'
              onClick={(event) => {
                event.preventDefault()
                this.onClickCancelCrop(event)
              }}
            />
            <Button
              type={1}
              width='50%'
              text={ this.state.isImageCropped ? 'Submit' : 'Crop' }
              onClick={!this.props.isPatient && !this.props.isPhysician ?
                (event) => {
                  event.preventDefault()
                  this.onClickSubmitTestImage(event)
                } :
                (event) => {
                  this.props.isPatient ?
                  this.onClickSubmitPersonImage(event, 'Patients') :
                  this.onClickSubmitPersonImage(event, 'Physicians')
                }
              }
            />
          </div>
        </div>
      </Fragment>
    )

    /* Render after selecting image */
    const croppingRender = () => {
      if (this.props.isTest && this.state.isImageSelected) {
        return (
          <TestUpload
            cropper={this.state.isLocationCropperRendered ? locationCropper : cropper}
            onClickCancel={(event) => this.onClickCancelCrop(event)}
            onClickNext={(event) => this.onClickNext(event)}
          />
        )
      } else {
        return personCropperContainer
      }
    }

    return (
      <Fragment>
        <form>
          <label className="upload-image-label" htmlFor="AddImageInput">
            {clickableRender()}
          </label>
          {this.props.isTest && this.props.isSubscriptionFull ? (
            <Link to='/settings'>
              <input
                hidden
                id="AddImageInput"
                type="file"
                accept="image/*"
                onChange={this.onSelectImage}
                onClick={(event)=> {
                  event.target.value = null
                }}
              />
            </Link> ) : (
            <input
              hidden
              id="AddImageInput"
              type="file"
              disabled={this.props.isDisabled}
              accept="image/*"
              onChange={this.onSelectImage}
              onClick={(event)=> {
                event.target.value = null
              }}
            />
            )
          }
          {croppingRender()}
        </form>
        { this.state.loading && <Loader /> }
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  personImageUrl: state.userReducer.personImageUrl,
  newTestData: state.userReducer.newTestData,
  currentPatient: state.userReducer.currentPatient,
  userData: state.firebaseReducer.userData,
})

export default connect(
    mapStateToProps,
    {postPersonImage, storeValue, storePayload, postTest},
)(ImageUpload)
