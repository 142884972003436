// Dependencies
import React, {useState, useEffect, Fragment} from 'react'
import {connect} from 'react-redux'
import {withCookies} from 'react-cookie'
import firebase from 'firebase/app'

// Actions
import {signIn} from '../actions/signIn'
import {signUp} from '../actions/signUp'
import {createFirebaseApp} from '../actions/createFirebaseApp'
import {storeValue} from '../actions/storeValue'

// Images
import lazarus from '../images/lazarus.svg'
import dm from '../images/dm.svg'

// Components
import SignInForm from './SignInForm'
import SignUpForm from './SignUpForm'

// Styles
import '../styles/Auth.css'
// import 'lazarus-cds/dist/index.css'

/*
  Renders Lazarus banner, Sign-in and Sign-up forms
  Handles Sign-in and Sign-up events
*/
function Auth(props) {
  const [userData, setUserData] = useState({})
  const [isSignUpPage, setIsSignUpPage] = useState(null)
  const [forgotPassword, setForgotPassword] = useState(false)

  /* Sign out deletes firestore */
  useEffect(() => {
    if (!props.firestore) {
      props.createFirebaseApp()
    }
  }, [])

  // useEffect(() => {
  //   if (props.authStatus === 'failure') {
  //     setUserData({})
  //   }
  // }, props.authStatus)

  useEffect(() => {
    if (props.match.params.orgId && !isSignUpPage) {
      setIsSignUpPage(true)
    }
  }, [props.match.params.orgId])

  useEffect(() => {
    if (props.signedUp) {
      setIsSignUpPage(false)
    }
  }, [props.signedUp])

  const onChangeForm = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    })
  }

  const onClickSignIn = () => {
    if (forgotPassword) {
      firebase.auth().sendPasswordResetEmail(userData.email)
          .then((res) => {
            alert('Please check your email to change your password')
            setForgotPassword(false)
          })
          .catch((err) => {
            // console.log('error', err)
            alert('Email not found. Please try again')
          })
    } else {
      props.signIn(
          userData.email,
          userData.password,
          props.cookies,
          props.firestore,
      )
    }
  }

  const onClickSignUp = () => {
    props.signUp(props.firestore, userData, props.match.params.orgId)
  }

  const onClickForgotPassword = () => {
    setForgotPassword(!forgotPassword)
  }

  return (
    <div className='auth-page page'>
      <Fragment>
        {/* {props.userMessage &&
          <Notification
            type={1}
            image={null}
            message={props.userMessage}
            time={Helpers.time()}
            isVisible={isNotificationVisible}
            onClick={onClickNotification}
          />
        } */}
        <div className='auth-left'>
          <div>
            <a href='https://www.lazarusai.com/' target="_blank" rel='noopener noreferrer'>
              <img src={lazarus} className="lazarus" />
            </a>
            <img src={dm} className="dm" />
            <p className='font-medium margin-top-0 margin-bottom-4em'>
              {isSignUpPage ? 'Sign Up' : 'Sign In'}
            </p>
          </div>
          <footer>
            <p className="margin-0 margin-bottom-2em">
              {isSignUpPage ?
              `Already Have an account?` :
              `Don't Have an account?`
              }
            </p>
            <p
              className='underline'
              onClick={() => {
                setIsSignUpPage(!isSignUpPage)
              }}
            >
              {!isSignUpPage ? 'Sign Up' : 'Sign In'}
            </p>
          </footer>
        </div>
        <div className="auth-right">
          {isSignUpPage ?
            <SignUpForm
              onChangeForm={onChangeForm}
              onClickSignUp={onClickSignUp}
              match={props.match}
              firestore={props.firestore}
            /> :
            <SignInForm
              onChangeForm={onChangeForm}
              onClickSignIn={onClickSignIn}
              forgotPassword={forgotPassword}
              onClickForgotPassword={onClickForgotPassword}
            />
          }
        </div>
      </Fragment>
    </div>
  )
}

const mapStateToProps = (state) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  signedUp: state.firebaseReducer.signedUp,
  isLoading: state.userReducer.isLoading,
  userMessage: state.userReducer.userMessage,
  authStatus: state.firebaseReducer.authStatus,
})

export default withCookies(connect(
    mapStateToProps,
    {signIn, signUp, createFirebaseApp, storeValue},
)(Auth))
