// Dependencies
import React from 'react'

// Styles
import '../styles/FullLoadingScreen.css'

// Components
import {
  Loader,
} from '@anishp16/lazarus-cds'

export default function FullLoadingScreen() {
  return (
    <div className='full-loading-screen'>
      <Loader />
    </div>
  )
}