// Dependencies
import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {withCookies} from 'react-cookie'

// Actions
import {storeValue} from '../actions/storeValue.js'
import {storePayload} from '../actions/storePayload.js'

// Images
import Dm from '../images/white/DmLogo.svg'
import Dashboard from '../images/white/Dashboard.svg'
import MProc from '../images/white/M-Proc.svg'
import Documentation from '../images/white/Documentation.svg'
import Settings from '../images/white/Settings.svg'
import User from '../images/white/User.svg'

// Styles
import '../styles/AsideNav.css'

// Actions
import {signOut} from '../actions/signOut'

/*
  Renders Lef-most Nav menu
  Handles redirects
*/
function AsideNav(props) {
  const {pages} = props
  const isSettings = window.location.href.includes('settings')
  return (
    <aside className='aside-nav'>
      <div>
        <div className='dm-div'>
          <img src={Dm} />
        </div>
        <Link
          to=''
          onClick={() => props.storeValue('path', 'Home')}
        >
          <div
            className={!isSettings && pages.dashboard ? 'aside-div selected-div' : 'aside-div'}
          >
            <img src={Dashboard} />
            <p>Dashboard</p>
          </div>
        </Link>
        {props.subData.status === 'active' ?
          <Link to='/mproc'>
            <div
              className={!isSettings && pages.mproc ? 'aside-div selected-div' : 'aside-div'}
            >
              <img src={MProc} />
              <p>M-Proc</p>
            </div>
          </Link> : (
            <div
              onClick={() => {
                props.storePayload({
                  userMessage: 'This is a paid feature, please go to settings to set up a subscription plan.',
                  notificationType: 3,
                  noticationIcon: 'warning',
                })
              }}
              className={pages.mproc ? 'aside-div selected-div' : 'aside-div'}
            >
              <img src={MProc} />
              <p>M-Proc</p>
            </div>
          )
        }
        {/* <div>
          <p>LiveScan</p>
        </div> */}
        {/* <Link to='documentation'>
          <div
            className={
              pages.documentation ?
              'aside-div selected-div' :
              'aside-div'
            }
          >
            <img src={Documentation} />
            <p>Documentation</p>
          </div>
        </Link> */}
      </div>
      <div>
        <Link
          to='/settings'
          onClick={() => props.storePayload({
            currentPatient: null,
            currentTest: null,
            path: 'Settings',
          })}
        >
          <div
            className={isSettings ? 'aside-div selected-div' : 'aside-div'}
          >
            <img src={Settings} />
            <p>Settings</p>
          </div>
        </Link>
        <div
          className='aside-div'
          onClick={async () => {
            await props.cookies.remove('email', {path: '/'})
            await props.cookies.remove('password', {path: '/'})
            props.signOut()
          }}
        >
          <img src={User} />
          <p>Sign Out</p>
        </div>
      </div>
    </aside>
  )
}

const mapStateToProps = (state, ownProps) => ({
  signedIn: state.userReducer.signedIn,
  pagesDict: state.userReducer.pagesDict,
  pages: state.userReducer.pages,
  cookies: ownProps.cookies,
  subData: state.userReducer.subscriptionData,
})

export default withCookies(
    connect(
        mapStateToProps, {signOut, storeValue, storePayload},
    )(AsideNav))
