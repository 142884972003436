import types from '../types'

const initialState = {
  database: {},
  firestore: {},
  storage: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_FIREBASE_APP:
      return {
        ...state,
        database: action.payload.database,
        firestore: action.payload.firestore,
        storage: action.payload.storage,
      }
    case types.SIGN_IN:
      return {
        ...state,
        signedIn: action.payload.signedIn,
        userMessage: action.payload.userMessage,
        /*
          authStatus used to set isLoading in App state after login attempt
          type: string ('success'/'failure')
        */
        authStatus: action.payload.authStatus,
        userData: action.payload.userData,
      }
    case types.SIGN_UP:
      return {
        ...state,
        ...action.payload,
      }
    case types.SIGN_OUT:
      return {
        signedIn: action.payload.signedIn,
        userMessage: action.payload.userMessage,
      }
    default:
      return state
  }
}