import React, {Fragment} from 'react'
import TopNav from './TopNav.js'
import AsideNav from './AsideNav'

// Wrapper for page to render with Nav
function WithNav(props) {
  const Component = props.component
  return (
    <Fragment>
      <TopNav />
      <AsideNav />
      <Component {...props} />
    </Fragment>
  )
}

export default WithNav
