import types from '../types'
import firebase from 'firebase/app'
import 'firebase/auth'

export const choosePlan = (sourceToken, plan, data) => (dispatch) => {
  dispatch({
    type: types.STORE_PAYLOAD,
    payload: {
      isLoading: true,
    },
  })
  const subNum = (data['subNum'] ? data['subNum'] : 1)
  const cusID = data['cusID']
  const sourceID = sourceToken['id']
  const orgID = data['orgId']


  const name = data.name
  const email = data.email

  if (cusID === 'None') {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    const rawSetSub = JSON.stringify(
        {
          'Email': email,
          'Goal': 'new',
          'Id': firebase.auth().currentUser.uid,
          'Name': name,
          'OrgId': orgID,
          'Source': sourceID,
          'SubItem': plan,
          'SubNum': subNum,
        },
    );

    const requestOptions2 = {
      method: 'POST',
      headers: myHeaders,
      body: rawSetSub,
      redirect: 'follow',
    };

    fetch(process.env.REACT_APP_URL + 'stripeSetSubscription', requestOptions2)
        .then((response) => response.json())
        .then((result2) => {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
              subscriptionData: {
                status: result2['status'],
                subPlan: result2['plan']['id'],
                subID: result2['id'],
                cusID: result2['customer'],
                amount: result2['items']['data'][0]['plan']['amount'],
                interval: result2['items']['data'][0]['plan']['interval'],
                start: result2['current_period_start'],
                end: result2['current_period_end'],
              },
            },
          })
          dispatch({
            type: types.LOADING_SUBSCRIPTION,
            payload: {
              isLoading: false,
              userMessage: 'Your subscription has been updated',
            },
          })
        })
        .catch((error) => {
          dispatch({
            type: types.LOADING_SUBSCRIPTION,
            payload: {
              isLoading: false,
              userMessage: 'There was an error updating your subscription',
            },
          })
        });
  } else {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    const rawSetSub2 = JSON.stringify({
      'SubItem': plan,
      'SubNum': subNum,
      'OrgId': orgID,
      'Goal': 'replace',
      'Source': sourceID,
    });

    const requestOptions2 = {
      method: 'POST',
      headers: myHeaders,
      body: rawSetSub2,
      redirect: 'follow',
    };

    fetch(process.env.REACT_APP_URL + 'stripeSetSubscription', requestOptions2)
        .then((response) => response.json())
        .then((result2) => {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              subscriptionData: {
                isLoading: false,
                status: result2['status'],
                subPlan: result2['plan']['id'],
                subID: result2['id'],
                cusID: result2['customer'],
                amount: result2['items']['data'][0]['plan']['amount'],
                interval: result2['items']['data'][0]['plan']['interval'],
                start: result2['current_period_start'],
                end: result2['current_period_end'],
              },
            },
          })
          dispatch({
            type: types.LOADING_SUBSCRIPTION,
            payload: {
              isLoading: false,
              userMessage: 'Your subscription has been updated',
            },
          })
        })
        .catch((error) => {
          dispatch({
            type: types.LOADING_SUBSCRIPTION,
            payload: {
              isLoading: false,
              userMessage: 'There was an error updating your subscription',
            },
          })
        })
  }
}
