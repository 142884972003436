import React from 'react'
import {connect} from 'react-redux'

// Components
import {Input, Button} from '@anishp16/lazarus-cds'
// import {Input, Button} from 'lazarus-cds'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
// import 'lazarus-cds/dist/index.css'

/*
  Renders sign-in form inside of Auth.js
*/
function SignInForm(props) {
  return (
    <div>
      <Input
        label='Email'
        type='text'
        name='email'
        onChange={props.onChangeForm}
      />
      <div className='flex w100 margin-bottom-2em'>
        {!props.forgotPassword &&
          <div className='margin-right-10px w50'>
            <Input
              label='Password'
              type='password'
              name='password'
              onChange={props.onChangeForm}
              width='50%'
            />
          </div>
        }
        <span
          className='underline w50'
          onClick={props.onClickForgotPassword}
        >
          {props.forgotPassword ? 'Back' : 'Forgot Password?'}
        </span>
      </div>
      <div>
        <Button
          text={props.forgotPassword ? 'Receive email' : 'Sign In'}
          onClick={props.onClickSignIn}
          isLoading={props.isLoading}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  isLoading: state.userReducer.isLoading,
})

export default connect(mapStateToProps)(SignInForm)
