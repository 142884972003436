import firebase from 'firebase/app'
import types from '../types.js'

export const fetchPatients = (firestore, orgId) => (dispatch) => {
  firestore.collection('LCDS') // Fetch userData
      .doc('Something')
      .collection('Organizations')
      .doc(orgId)
      .collection('Patients')
      // Only pull patients associated with user
      .where('SharedWith.' + firebase.auth().currentUser.uid, '==', true)
      .onSnapshot((res) => {
        if (res.docs) {
          const patientsDocsArr = res.docs
          const patientsObj = {}
          let patientList = patientsDocsArr.map((patientDoc) => {
            const patient = patientDoc.data()
            patientsObj[patient.ID] = patient
            return patient
          })
          patientList = patientList.sort((a, b) => {
            return b.LastUpdate - a.LastUpdate
          })
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              // isLoading: false,
              patientList,
              patientsObj,
            },
          })
        } else {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              // isLoading: false,
              patientList: null,
              patientsObj: null,
            },
          })
        }
      })
}
