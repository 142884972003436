// Dependencies
import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import StripeCheckout from 'react-stripe-checkout'

// Actions
import { choosePlan } from '../actions/choosePlan'
import { postPromoCode } from '../actions/postPromoCode'
import { refreshSubData } from '../actions/refreshSubData'
import { storePayload } from '../actions/storePayload'

// Components
import { Button, Input, Meter } from '@anishp16/lazarus-cds'
import FullLoadingScreen from './FullLoadingScreen'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/Billing.css'

// Images
import dm from '../images/dm.svg'

function Billing(props) {
  const defaultPlanType = {
    monthly: false,
    yearly: false,
    per: false,
  }
  const initialPlanType = {
    monthly: true,
    yearly: false,
    per: false,
  }
  const [userMessage, setUserMessage] = useState(null)
  const [promoCode, setPromoCode] = useState(null)
  const [isSelectingPlan, setIsSelectingPlan] = useState(false)
  const [planTypes, setPlanTypes] = useState(initialPlanType)

  useEffect(() => {
    // setUsageFraction()
  }, [props.usageData])

  useEffect(() => {
    const isActive = props.subData && props.subData.status && props.subData.status === 'active'
    if (!isActive) {
      onClickChangePlan()
    }
  }, [props.subData])

  const onClickChangePlan = () => {
    setIsSelectingPlan(true)
  }
  const onClickApplyPromo = (event) => {
    event.preventDefault()
    if (props.subData.subPlan !== 'Promo' || (props.subData.subPlan !== 'None')) {
      props.postPromoCode(props.firestore, promoCode, props.userData.Membership.Organization)
    } else {
      alert('You have already used a promo code.')
    }
  }

  const changePlanTypes = (type) => {
    setPlanTypes({
      ...defaultPlanType,
      [type]: true,
    })
  }

  const onClickChangeCreditCardToken = (token) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    const raw = JSON.stringify({
      Source: token['id'],
      OrgId: props.userData.Membership.Organization,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(process.env.REACT_APP_URL + 'stripeChangePayment', requestOptions)
      .then((response) => response.text())
      .then(() => {
        props.refreshSubData(props.firestore, props.userData.Membership.Organization)
      })
    // .catch((error) => console.log('error', error))
  }

  const planCard = (level, patients, price, interval, plan) => {
    return (
      <div className='plan-card'>
        <div className='card-top'>
          <h4 className='margin-top-0'>
            {level ? `LEVEL ${level}` : 'PER PATIENT PLAN'}
          </h4>
        </div>
        {level ?
          <Fragment>
            <p>Up to</p>
            <h2>{patients}</h2>
            <p>patient accounts</p>
            <p>{`$${price} / ${interval}`}</p>
          </Fragment> :
          <Fragment>
            <p>{`$${price}`}</p>
            <p>per patient</p>
          </Fragment>
        }
        {(props.subData.subPlan === plan) ?
          (
            <Button
              text='Current Plan'
              type={2}
              onClick={samePlan}
              width={'calc(100% - 2em)'}
              style={{ position: 'absolute', bottom: '1em' }}
            />
          ) :
          (
            <StripeCheckout
              stripeKey={process.env.REACT_APP_STRIPE_KEY}
              token={(token) => onClickStart(token, plan)}
              email={props.userData ? props.userData.Info.Email : null}
            >
              <Button
                text='Change Plan'
                type={1}
                width={'calc(100% - 2em)'}
                style={{ position: 'absolute', bottom: '1em' }}
              />
            </StripeCheckout>
          )
        }
      </div>
    )
  }

  const samePlan = () => {
    // alert('You are already using this plan.')
    props.storePayload({
      userMessage: 'You are already using this plan',
      notificationType: 3,
      notificationIcon: 'warning',
    })
  }

  const onClickStart = async (token, plan) => {
    const data = {
      ...props.subData,
      name: props.userData.Info.Name,
      email: props.userData.Info.ContactEmail,
      orgId: props.userData.Membership.Organization,
    }
    props.choosePlan(token, plan, data)
  }

  const planDetails =
  {
    'testing-yearly': {
      Name: 'LEVEL 1',
      Interval: '/ year',
      Num: 1200,
      Price: 1,
    },
    'basic-monthly': {
      Name: 'LEVEL 1',
      Interval: '/ month',
      Num: 100,
      Price: 250,
    },
    'standard-monthly': {
      Name: 'LEVEL 2',
      Interval: '/ month',
      Num: 200,
      Price: 500,
    },
    'premium-monthly': {
      Name: 'LEVEL 3',
      Interval: '/ month',
      Num: 400,
      Price: 1000,
    },
    'basic-yearly': {
      Name: 'LEVEL 1',
      Interval: '/ year',
      Num: 1200,
      Price: 2750,
    },
    'standard-yearly': {
      Name: 'LEVEL 2',
      Interval: '/ year',
      Num: 2400,
      Price: 5500,
    },
    'premium-yearly': {
      Name: 'LEVEL 2',
      Interval: '/ year',
      Num: 4800,
      Price: 11000,
    },
    'Promo': {
      Name: 'PROMO',
      Interval: '/ month',
      Num: 100,
      Price: 0,
    },
    'None': {
      Name: 'NO PLAN SELECTED',
      Interval: '/ month',
      Num: 0,
      Price: 0,
    },
    'metered-patient': {
      Name: 'Metered',
      Interval: '/ month',
      Num: 'Unlimited',
      Price: 10,
    },
    'error': {
      Name: 'Error',
      Interval: '/ month',
      Num: 0,
      Price: 0,
    },
  }

  return (
    <div
      className={
        isSelectingPlan ?
          'billing-content choose-plan' :
          'billing-content'
      }
    >
      {props.isLoading && <FullLoadingScreen />}
      {!isSelectingPlan &&
        <div className='version-card'>
          <div className='card-top'>
            <img src={dm} alt='Dm' />
          </div>
          <h2>Dermatology</h2>
          <p className='font-small'>Beta 3.0</p>
        </div>
      }
      {!isSelectingPlan &&
        <div className='current-plan'>
          <div className='card-top'>
            <h4 className='margin-top-0'>{(props.subData.subPlan !== 'Promo') ? planDetails[props.subData.subPlan]['Name'] : props.subData.subPlan}</h4>
          </div>
          {props.subData.subPlan === 'metered-patient' ?
            <Fragment>
              <p>$10</p>
              <p>per patient</p>
            </Fragment> :
            <Fragment>
              <p>Up to</p>
              <h2>{(props.subData.subPlan !== 'Promo') ? (props.subData.amount / planDetails[props.subData.subPlan]['Price'] / 100 * planDetails[props.subData.subPlan]['Num']) : '100'}</h2>
              <p>patient accounts</p>
              <p>${(props.subData.subPlan !== 'Promo') ? (props.subData.amount / 100) : (0)} {planDetails[props.subData.subPlan]['Interval']}</p>
            </Fragment>
          }
          <Button
            text='Change Plan &#10141;'
            type={2}
            onClick={onClickChangePlan}
            width={'calc(100% - 2em)'}
            style={{ position: 'absolute', bottom: '1em' }}
          />
        </div>
      }
      {!isSelectingPlan &&
        <div className='usage-and-creditCard'>
          {(props.subData.subPlan !== 'metered-patient') ? (
            <div className='usage'>
              <h2>Current Usage</h2>
              <p>{props.usageData && (props.usageData.currentUsage + '/' + props.usageData.maxUsage)}</p>
              <Meter percent={100 * (props.usageData.currentUsage / props.usageData.maxUsage)} />
              {/* <p>You will be charged $2 per patient past your limit</p> */}
            </div>
          ) : (
              <div className='usage'>
                <h2>Current Usage</h2>
                <p>{props.usageData && (props.usageData.currentUsage + ' patients this month')}</p>
                <p>{props.usageData && ('You will be charged $' + props.usageData.currentUsage + '0 this month')}</p>
                {/* <p>You will be charged $2 per patient past your limit</p> */}
              </div>
            )}
          <div className='payment-container'>
            <div>
              <h2>Payment</h2>
              <StripeCheckout
                stripeKey={process.env.REACT_APP_STRIPE_KEY}
                token={(token) => onClickChangeCreditCardToken(token)}
                email={props.userData ? props.userData.Info.Email : null}
              >
                <span>Edit</span>
              </StripeCheckout>
            </div>
            <div className='credit-card'>
              <div>
                <div className='gray-div' />
                <p>{props.subData.brand} {props.subData.type} card</p>
              </div>
              <p className='bold'>****{props.subData.dig4}</p>
            </div>
          </div>
        </div>
      }
      {isSelectingPlan &&
        <div className='monthly-yearly-container'>
          <div className='small-version-card'>
            <div className='card-top'>
              <img src={dm} alt='Dm' />
            </div>
            <h2>Dermatology</h2>
            <p className='font-small'>Beta 3.0</p>
          </div>
          <div className='monthly-yearly-buttons'>
            <Button
              text='Monthly'
              type={planTypes.monthly ? 1 : 2}
              onClick={() => changePlanTypes('monthly')}
              style={{ marginBottom: '0.5em', height: '50%' }}
            />
            <Button
              text='Yearly'
              type={planTypes.yearly ? 1 : 2}
              onClick={() => changePlanTypes('yearly')}
              style={{ marginBottom: '0.5em', height: '50%' }}
            />
            <Button
              text='Per Patient'
              type={planTypes.per ? 1 : 2}
              onClick={() => changePlanTypes('per')}
              style={{ marginBottom: '0.5em', height: '50%' }}
            />
          </div>
        </div>
      }
      <div className='promo-container'>
        <Input
          label='Insert your promo code'
          onChange={(e) => setPromoCode(e.target.value)}
        />
        <div className='user-message-container'>
          {userMessage &&
            <p className='user-message'>{userMessage}</p>
          }
        </div>
        <Button
          type={2}
          text='Apply'
          style={{ margin: '0 0 1em 2em' }}
          onClick={onClickApplyPromo}
        />
      </div>
      {isSelectingPlan &&
        <div className='plan-selection-container'>
          {planTypes.monthly && (
            <Fragment>
              {planCard(1, 100, 250, 'month', 'basic-monthly')}
              {planCard(2, 200, 500, 'month', 'standard-monthly')}
              {planCard(3, 400, 1000, 'month', 'premium-monthly')}
            </Fragment>
          )}
          {planTypes.yearly && (
            <Fragment>
              {planCard(1, 1200, 2750, 'year', 'basic-yearly')}
              {planCard(2, 2400, 5500, 'year', 'standard-yearly')}
              {planCard(3, 4800, 11000, 'year', 'premium-yearly')}
            </Fragment>
          )}
          {planTypes.per && (
            <Fragment>
              {planCard(null, null, 10, 'metered-patient', 'metered-patient')}
            </Fragment>
          )}
        </div>
      }
      {isSelectingPlan &&
        <div className='cancel-btn-container'>
          <Button
            type={2}
            text="Cancel"
            onClick={() => setIsSelectingPlan(false)}
          />
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  userData: state.firebaseReducer.userData,
  orgData: state.userReducer.orgData,
  orgPhysiciansData: state.userReducer.orgPhysiciansData,
  isSubscriptionFull: state.userReducer.isSubscriptionFull,
  subData: state.userReducer.subscriptionData,
  usageData: state.userReducer.usageData,
  isLoading: state.userReducer.isLoading,
})

export default connect(
  mapStateToProps,
  {
    choosePlan,
    postPromoCode,
    refreshSubData,
    storePayload,
  },
)(Billing)
