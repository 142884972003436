// Dependencies
import React, {useState, Fragment, useEffect} from 'react'
import {connect} from 'react-redux'
import Helpers from '../Helpers.js'
import {Link} from 'react-router-dom'

// Components
import {
  TestSetThumb,
  TestThumb,
  MoleTrackingThumb,
  MoleTrackingThumbnail,
} from '@anishp16/lazarus-cds'
// } from 'lazarus-cds'

// Actions
import {storeValue} from '../actions/storeValue.js'
import {storePayload} from '../actions/storePayload.js'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
// import 'lazarus-cds/dist/index.css'
import '../styles/TestThumbs.css'

// Images
import dog from '../images/dog.jpg'
import FullLoadingScreen from './FullLoadingScreen.js'

function TestThumbs(props) {
  // Selected test set shows individual test thumbs
  const [selectedTestSet, setSelectedTestSet] = useState(null)
  // Prevents animation on page render
  const [renderedTestThumbs, setRenderedTestThumbs] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    /* If there are no tests yet or the tests are loaded from Patient.js */
    if (props.fullTestList && (!props.fullTestList.length || props.tests.length)) {
      setIsLoading(false)
    }
  }, [props.tests])

  useEffect(() => {
  }, [renderedTestThumbs])

  const onClickTestSetThumb = (test, i) => {
    if (selectedTestSet === i) {
      // props.onClickTestSetThumb(null)
      setSelectedTestSet(null)
    } else {
      // Set new coordinates
      props.onClickTestSetThumb(test)
      setSelectedTestSet(i)
      setRenderedTestThumbs({
        ...renderedTestThumbs,
        [i]: true,
      })
    }
  }

  const patientId = props.match.params.patientId

  return (
    <div className='test-thumbs'>
      {isLoading ? <FullLoadingScreen /> :
        <Fragment>
          {props.tests.map((test, i) => {
            if (test.Type === 'Local') {
              if (!test.Reasoning) {
                return (
                  <div
                    key={i + 1}
                    onClick={() => onClickTestSetThumb(test, i + 1)}
                    onMouseEnter={() => {
                      if (!selectedTestSet) {
                        props.onHoverTestSetThumb(test.MoleCoordinates, test)
                      }
                    }}
                    onMouseLeave={() => {
                      if (!selectedTestSet) {
                        props.onMouseLeaveTestSetThumb()
                      }
                    }}
                  >
                    <TestSetThumb
                      isAIActive={false}
                      key={i + 1}
                      id={`${test.Id.slice(0, 8)}...`}
                      date={Helpers.timeStampToDate(test.DateTaken)}
                      type='local'
                      condition={'n/a'}
                      risk={'red'}
                      potentialConcern={true}
                      confidence={'n/a'}
                      location={test.MoleLocation || 'Location N/A'}
                      locationCoordinates={test.MoleCoordinates || [0, 0]}
                      isClicked={selectedTestSet === i + 1}
                    />
                    {renderedTestThumbs[i + 1] &&
                    <div onClick={() => {
                      props.storePayload({
                        userMessage: 'This test did not receive AI results. Please make sure you wait for a confirmation screen before exiting the app during image upload',
                        notificationType: 3,
                        notificationIcon: 'warning',
                      })
                    }}>
                      <TestThumb
                        isAIActive={false}
                        isShowing={selectedTestSet === i + 1}
                        image={test.ImageRef}
                        reasoning={'n/a'}
                        notes={test.Notes}
                        flag={test.Flag}
                        condition1={'n/a'}
                        condition2={'n/a'}
                        confidence1={'n/a'}
                        confidence2={'n/a'}
                        risk1={'red'}
                        risk2={'red'}
                        style={{marginTop: '1em'}}
                      />
                    </div>
                    }
                  </div>
                )
              } else {
                const melanomaObj = test.Reasoning.filter((condition) => {
                  return condition.Name === 'Melanoma'
                })[0]
                const hightToLowConfidenceReasoning = Helpers.sortedReasoning(test, test.Reasoning)
                const confPercent = test.Confidence * 100
                return (
                  <div
                    key={i + 1}
                    onClick={() => onClickTestSetThumb(test, i + 1)}
                    onMouseEnter={() => {
                      if (!selectedTestSet) {
                        props.onHoverTestSetThumb(test.MoleCoordinates, test)
                      }
                    }}
                    onMouseLeave={() => {
                      if (!selectedTestSet) {
                        props.onMouseLeaveTestSetThumb()
                      }
                    }}
                  >
                    <TestSetThumb
                      isAIActive={props.isAIActive}
                      key={i + 1}
                      id={`${test.Id.slice(0, 8)}...`}
                      date={Helpers.timeStampToDate(test.DateTaken)}
                      type='local'
                      condition={Helpers.isPossibleMelanoma(test) ? 'Possible Melanoma' : test.Results}
                      risk={Helpers.isPossibleMelanoma(test) ? 'orange' : Helpers.returnOverallRiskLevel(test, test.Results)}
                      potentialConcern={
                        Helpers.returnOverallRiskLevel(test, test.Results) === 'red' ||
                        Helpers.returnOverallRiskLevel(test, test.Results) === 'orange' ||
                        Helpers.isMelanomaOrIsPossibleMelanoma(test)
                        // || check for potential melanoma
                      }
                      confidence={Helpers.isPossibleMelanoma(test) ? melanomaObj.Confidence.toString().slice(0, 4) : confPercent.toString().slice(0, 4)}
                      location={test.MoleLocation || 'Location N/A'}
                      locationCoordinates={test.MoleCoordinates || [0, 0]}
                      isClicked={selectedTestSet === i + 1}
                    />
                    {renderedTestThumbs[i + 1] &&
                    <Link
                      to={`/patient/${patientId}/test/${test.Id}`}
                      onClick={() => {
                        props.storeValue('currentTest', test)
                      }}
                    >
                      <TestThumb
                        isAIActive={props.isAIActive}
                        isShowing={selectedTestSet === i + 1}
                        image={test.ImageRef}
                        reasoning={hightToLowConfidenceReasoning}
                        notes={test.Notes}
                        flag={test.Flag}
                        condition1={hightToLowConfidenceReasoning[0].Name}
                        condition2={hightToLowConfidenceReasoning[1].Name}
                        confidence1={hightToLowConfidenceReasoning[0].Confidence.toString().slice(0, 5)}
                        confidence2={hightToLowConfidenceReasoning[1].Confidence.toString().slice(0, 5)}
                        risk1={hightToLowConfidenceReasoning[0].Risk}
                        risk2={hightToLowConfidenceReasoning[1].Risk}
                        style={{marginTop: '1em'}}
                      />
                    </Link>
                    }
                  </div>
                )
              }
            } else if (test.Type === 'M-Proc') { // MProc test sets
              // Handle m-proc tests that failed to get AI results
              if (!test.TestSets[0].Tests[0].data.Reasoning) {
                return (
                  <div
                    key={i + 1}
                    onClick={() => onClickTestSetThumb(test.TestSets[0], i + 1)}
                    onMouseEnter={() => {
                      if (!selectedTestSet) {
                        props.onHoverTestSetThumb(test.TestSets[0].MoleCoordinates, test.TestSets[0])
                      }
                    }}
                    onMouseLeave={() => {
                      if (!selectedTestSet) {
                        props.onMouseLeaveTestSetThumb()
                      }
                    }}
                  >
                    <TestSetThumb
                      isAIActive={false}
                      key={i + 1}
                      id={test.Id.slice(0, 8) + '...'}
                      date={Helpers.timeStampToDate(test.DateTaken)}
                      type='mproc'
                      condition={'n/a'}
                      confidence={'n/a'}
                      risk={'red'}
                      potentialConcern={true}
                      location={test.TestSets[0].MoleLocation}
                      locationCoordinates={test.MoleCoordinates || [0, 0]}
                      isClicked={selectedTestSet === i + 1}
                    />
                    {renderedTestThumbs[i + 1] &&
                      <Fragment>
                        {test.TestSets[0].Tests.map((mprocTest, j) => {
                          // const melanomaObj = mprocTest.data.Reasoning.filter((condition) => {
                          //   return condition.Name === 'Melanoma'
                          // })[0]
                          return (
                            <div
                              key={j}
                              onClick={() => {
                                props.storePayload({
                                  userMessage: 'This test did not receive AI analysis. Please request another test from this patient and tell them to wait for an upload confirmation before exiting the app.',
                                  notificationType: 3,
                                  notificationIcon: 'warning',
                                })
                              }}
                            >
                              <TestThumb
                                isAIActive={false}
                                isShowing={selectedTestSet === i + 1}
                                image={mprocTest.data.ImageRef}
                                reasoning={'n/a'}
                                notes={mprocTest.data.Notes}
                                flag={mprocTest.data.Flag}
                                condition1={'n/a'}
                                condition2={'n/a'}
                                confidence1={'n/a'}
                                confidence2={'n/a'}
                                risk1={'n/a'}
                                risk2={'n/a'}
                                style={{marginTop: '1em'}}
                              />
                            </div>
                          )
                        })}
                      </Fragment>
                    }
                  </div>
                )
              }
              const topConditionObj = Helpers.returnTopCondition(test.TestSets[0].Tests)
              return (
                <div
                  key={i + 1}
                  onClick={() => onClickTestSetThumb(test.TestSets[0], i + 1)}
                  onMouseEnter={() => {
                    if (!selectedTestSet) {
                      props.onHoverTestSetThumb(test.TestSets[0].MoleCoordinates, test.TestSets[0])
                    }
                  }}
                  onMouseLeave={() => {
                    if (!selectedTestSet) {
                      props.onMouseLeaveTestSetThumb()
                    }
                  }}
                >
                  <TestSetThumb
                    isAIActive={props.isAIActive}
                    key={i + 1}
                    id={test.Id.slice(0, 8) + '...'}
                    date={Helpers.timeStampToDate(test.DateTaken)}
                    type='mproc'
                    condition={topConditionObj.topCondition !== 'Melanoma' && topConditionObj.isPossibleMelanoma ? 'Possible Melanoma' : topConditionObj.topCondition}
                    confidence={
                      (topConditionObj.confidenceRange ?
                      `${topConditionObj.confidenceRange[0].toString().slice(0, 5)}-${topConditionObj.confidenceRange[1].toString().slice(0, 5)}` :
                      topConditionObj.confidence.toString().slice(0, 5))
                    }
                    risk={topConditionObj.topCondition !== 'Melanoma' && topConditionObj.isPossibleMelanoma ? 'orange' : Helpers.returnConditionRiskLevel(topConditionObj.topCondition)}
                    potentialConcern={topConditionObj.isHighConcern}
                    location={test.TestSets[0].MoleLocation}
                    locationCoordinates={test.MoleCoordinates || [0, 0]}
                    isClicked={selectedTestSet === i + 1}
                  />
                  {renderedTestThumbs[i + 1] &&
                  <Fragment>
                    {test.TestSets[0].Tests.map((mprocTest, j) => {
                      // const melanomaObj = mprocTest.data.Reasoning.filter((condition) => {
                      //   return condition.Name === 'Melanoma'
                      // })[0]
                      const hightToLowConfidenceReasoning = Helpers.sortedReasoning(mprocTest, mprocTest.data.Reasoning)
                      return (
                        <Link
                          key={j}
                          to={`/patient/${patientId}/test/${test.Id}/${mprocTest.id}`}
                          onClick={() => {
                            props.storePayload({
                              currentTestSet: test,
                              currentTest: mprocTest,
                              path: 'test',
                            })
                          }}
                        >
                          <TestThumb
                            isAIActive={props.isAIActive}
                            isShowing={selectedTestSet === i + 1}
                            image={mprocTest.data.ImageRef}
                            reasoning={hightToLowConfidenceReasoning}
                            notes={mprocTest.data.Notes}
                            flag={mprocTest.data.Flag}
                            condition1={hightToLowConfidenceReasoning[0].Name}
                            condition2={hightToLowConfidenceReasoning[1].Name}
                            confidence1={hightToLowConfidenceReasoning[0].Confidence.toString().slice(0, 5)}
                            confidence2={hightToLowConfidenceReasoning[1].Confidence.toString().slice(0, 5)}
                            risk1={hightToLowConfidenceReasoning[0].Risk}
                            risk2={hightToLowConfidenceReasoning[1].Risk}
                            style={{marginTop: '1em'}}
                          />
                        </Link>
                      )
                    })}
                  </Fragment>
                  }
                </div>

              )
            } else if (test.Type === 'Mole-Tracking') {
              const isReviewRequested =
                test.PingTime ? (
                  test.LastReview ? (
                    test.PingTime > test.LastReview.Time) : (
                    true
                  )
                ) : (
                  false
                )

              if (!test.Reasoning) {
                let emptyImages
                const areEmptyImages = test.Tests.length < 4
                if (areEmptyImages) {
                  const arrayLength = 4 - test.Tests.length
                  emptyImages = Array(arrayLength).fill('box')
                  // console.log('empty images', emptyImages)
                }
                let imageLinks =
                  test.Tests.map((kTest, k) => {
                    // console.log('test id', kTest)
                    return (
                      <Link
                        to={`/patient/${patientId}/moleTrackingTest/${kTest.id}/${k}`}
                        key={'image-' + k}
                        onClick={() => props.storePayload({
                          currentTest: test,
                        })}
                        style={{
                          height: '100%',
                        }}
                      >
                        <img
                          src={kTest.ImageRef}
                          alt={'test-' + k}
                          className='moleTracking-image'
                          style={{
                            border: `${k === 0 || k === test.Tests.length - 1 ? '3px solid black' : ''}`,
                            boxSizing: `${k === 0 || k === test.Tests.length - 1 ? 'border-box' : ''}`,
                            height: '100%',
                            width: 'auto',
                          }}
                        />
                      </Link>
                    )
                  })

                if (areEmptyImages) {
                  const emptyImageRender =
                    emptyImages.map((emptyImage, l) => {
                      return (
                        <div
                          key={l}
                          style={
                            {
                              background: 'grey',
                              height: '100%',
                              width: '6.5em',
                              cursor: 'auto',
                              border: '1px solid black',
                            }
                          }
                        />
                      )
                    })
                  const firstPart =
                    test.Tests.length === 1 ?
                      [imageLinks[0]] :
                      imageLinks.slice(0, imageLinks.length - 1)
                  const middlePart =
                    test.Tests.length === 1 ?
                      emptyImageRender.slice(0, 3) :
                      emptyImageRender
                  const lastPart =
                    test.Tests.length === 1 ?
                      [] :
                      imageLinks[imageLinks.length - 1]
                  imageLinks = firstPart.concat(middlePart).concat(lastPart)
                  // console.log('image links', imageLinks)
                }
                // console.log('missing results')
                return (
                  <div
                    key={i + 1}
                    onClick={() => onClickTestSetThumb(test, i + 1)}
                    onMouseEnter={() => {
                      if (!selectedTestSet) {
                        props.onHoverTestSetThumb(test.MoleCoordinates, test)
                      }
                    }}
                    onMouseLeave={() => {
                      if (!selectedTestSet) {
                        props.onMouseLeaveTestSetThumb()
                      }
                    }}
                  >
                    <MoleTrackingThumb
                      key={i + 1}
                      id={`${test.Id.slice(0, 8)}...`}
                      location={test.MoleLocation || 'Location N/A'}
                      locationCoordinates={test.MoleCoordinates || [0, 0]}
                      isClicked={selectedTestSet === i + 1}
                      date1={Helpers.timeStampToDate(test.DateTaken)}
                      date2={
                        Helpers.timeStampToDate(test.Tests[test.Tests.length - 1].DateTaken)
                      }
                      // lastUpdate=''
                      isReviewRequested={isReviewRequested}
                      imageNumber={test.Tests.length}
                    />
                    {renderedTestThumbs[i + 1] &&
                      <MoleTrackingThumbnail
                        imageLinks={imageLinks}
                        isAIActive={false}
                        isShowing={selectedTestSet === i + 1}
                        image={test.ImageRef}
                        images={test.Tests}
                        reasoning={'n/a'}
                        notes={test.PhysicianNotes}
                        flag={test.Flag}
                        condition1={'n/a'}
                        condition2={'n/a'}
                        confidence1={'n/a'}
                        confidence2={'n/a'}
                        risk1={'red'}
                        risk2={'red'}
                        style={{marginTop: '1em'}}
                        link={window.location.href + `/moleTrackingTest/${test.Id}/`}
                      />
                    }
                  </div>
                )
              }
            }
          })}
        </Fragment>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentPatient: state.userReducer.currentPatient,
  isAIActive: state.userReducer.isAIActive,
})

export default connect(
    mapStateToProps,
    {storeValue, storePayload},
)(TestThumbs)
