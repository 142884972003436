import React, {useEffect} from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'

// Tooltips for Material UI accuracy slider
function ValueLabel(props) {
  const {children, open, value} = props

  const popperRef = React.useRef(null)
  useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update()
    }
  }, [])

  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      open={open}
      enterTouchDelay={0}
      placement='top'
      title={value}
    >
      {children}
    </Tooltip>
  );
}

ValueLabel.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
}

export default ValueLabel
