const types = {
  CREATE_FIREBASE_APP: 'CREATE_FIREBASE_APP',
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  SIGN_OUT: 'SIGN_OUT',
  STORE_VALUE: 'STORE_VALUE',
  STORE_PAYLOAD: 'STORE_PAYLOAD',
  CREATE_PATIENT: 'CREATE_PATIENT',
  LOADING_SUBSCRIPTION: 'LOADING_SUBSCRIPTION'
}

export default types
