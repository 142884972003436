// Dependencies
import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import firebase from 'firebase/app'

// Actions
import {storePayload} from '../actions/storePayload'

// Components
import {Button, Input} from '@anishp16/lazarus-cds'
import FullLoadingScreen from './FullLoadingScreen'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/ChangePassword.css'

function ChangePassword(props) {
  const [passwordForm, setPasswordForm] = useState({})
  const [userMessage, setUserMessage] = useState(null)

  useEffect(() => {
    const changePasswordForm = document.getElementById('change-password-form')
    if (changePasswordForm) {
      document.getElementById('change-password-form').reset()
      setPasswordForm({})
    }
  }, [props.isLoading])

  const onChangeInput = (event) => {
    setPasswordForm({
      ...passwordForm,
      [event.target.name]: event.target.value,
    })
  }

  const onClickSubmit = async (event) => {
    event.preventDefault()
    await props.storePayload({
      isLoading: true,
    })
    if (!passwordForm || !passwordForm.newPassword ||
      !passwordForm.confirmNewPassword ||
      !passwordForm.currentPassword ||
      passwordForm.newPassword.length === 0 ||
      passwordForm.confirmNewPassword.length === 0 ||
      passwordForm.currentPassword.length === 0) {
      props.storePayload({
        isLoading: false,
        userMessage: 'One or more fields are empty',
        notificationType: 2,
        notificationIcon: 'warning',
      })
      // setUserMessage("One or more fields are empty")
    } else if (passwordForm.newPassword !== passwordForm.confirmNewPassword) {
      props.storePayload({
        isLoading: false,
        userMessage: `Passwords don't match`,
        notificationType: 2,
        notificationIcon: 'warning',
      })
      // setUserMessage("Passwords don't match")
    } else if (passwordForm.newPassword.length < 7) {
      props.storePayload({
        isLoading: false,
        userMessage: 'Password must be at least 7 characters long',
        notificationType: 2,
        notificationIcon: 'warning',
      })
      // setUserMessage("Password must be at least 7 characters long")
    } else {
      const user = firebase.auth().currentUser
      const cred = firebase.auth.EmailAuthProvider.credential(
          firebase.auth().currentUser.email,
          passwordForm.currentPassword,
      )
      user.reauthenticateWithCredential(cred)
          .then(() => {
            user.updatePassword(passwordForm.newPassword)
                .then(() => {
                  props.storePayload({
                    isLoading: false,
                    userMessage: 'Success! Your password has been updated',
                    notificationType: 2,
                    notificationIcon: 'check',
                  })
                  // setUserMessage("Your password has been updated")
                }).catch(() => {
                  props.storePayload({
                    isLoading: false,
                    userMessage: 'There was an error changing your password',
                    notificationType: 2,
                    notificationIcon: 'warning',
                  })
                  // setUserMessage("There was an error changing your password")
                })
          }).catch(() => {
            props.storePayload({
              isLoading: false,
              userMessage: 'Your current password is incorrect',
              notificationType: 2,
              notificationIcon: 'warning',
            })
          })
    }
  }

  return (
    <div className='change-password-content'>
      {props.isLoading && <FullLoadingScreen />}
      <h1>Reset Password</h1>
      <form id='change-password-form'>
        <Input
          label='Current Password'
          name='currentPassword'
          onChange={onChangeInput}
          type='password'
        />
        <Input
          label='New Password'
          name='newPassword'
          onChange={onChangeInput}
          type='password'
        />
        <Input
          label='Confirm New Password'
          name='confirmNewPassword'
          onChange={onChangeInput}
          type='password'
        />
        <div className='user-message-container'>
          {userMessage &&
            <p className='user-message'>{userMessage}</p>
          }
        </div>
        <Button
          type={1}
          text='Submit'
          onClick={onClickSubmit}
        />
      </form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  isLoading: state.userReducer.isLoading,
})

export default connect(mapStateToProps, {storePayload})(ChangePassword)
