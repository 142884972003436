// Dependencies
import React, {useState} from 'react'
import {connect} from 'react-redux'

// Actions
import {signUp} from '../actions/signUp'

// Components
import {Input, Button} from '@anishp16/lazarus-cds'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'

/*
  Renders sign-up form inside of Auth.js
*/
function SignUpForm(props) {

  const [form, setForm] = useState({})


  const onChangeForm = (event, name, value) => {
    if (event) {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      })
    } else {
      setForm({
        ...form,
        [name]: value,
      })
    }
  }

  const signUpClick = () => {
    props.signUp(props.firestore, form, (props.match.params.orgId ? props.match.params.orgId : null))
  }

  return (
    <div>
      <Input
        label='First Name'
        type='text'
        name='firstName'
        onChange={onChangeForm}
      />
      <Input
        label='Last Name'
        type='text'
        name='lastName'
        onChange={onChangeForm}
      />
      <Input
        label='Contact E-mail'
        type='text'
        name='email'
        onChange={onChangeForm}
      />
      <Input
        label='Practice Name'
        type='text'
        name='practiceName'
        onChange={onChangeForm}
      />
      <Input
        label='Practice Address'
        type='text'
        name='practiceAddress'
        onChange={onChangeForm}
      />
      <div className='flex w100 margin-bottom-2em'>
        <div className='margin-right-10px w50'>
          <Input
            label='Password'
            type='password'
            name='password'
            onChange={onChangeForm}
            width='50%'
          />
        </div>
        <Input
          label='Confirm Password'
          type='password'
          name='confirmPassword'
          onChange={onChangeForm}
          width='50%'
        />
      </div>
      <div>
        <Button
          text='Sign Up'
          onClick={ signUpClick }
          type={2} // White Button
        />
      </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
})

export default connect(
    mapStateToProps,
    {signUp},
)(SignUpForm)
