import types from '../types'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import {fetchOrgData} from './fetchOrgData'
import {fetchPatients} from './fetchPatients'

/*
  Successfull signIn will dispatch fetchPatients and fetchOrgData
  fetchOrgData will asign signedIn and isLoading boolean in reducer
*/

export const signIn = (
    email,
    password,
    cookies,
    firestore,
) => (dispatch) => {
  if (!password || !email) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        userMessage: 'Please include email and password',
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    firebase.auth().signInWithEmailAndPassword(email, password)
        .then(signInSuccessCB)
        .catch(signInFailureCB)
  }

  /*  Response from successful signin is only stored if userData
      is successfully pulled. Userdata is stored along with signedIn/authStatus
      which triggers reroute
  */
  function signInSuccessCB(response) {
    // dispatch({
    //   type: types.STORE_PAYLOAD,
    //   payload: {
    //     isLoading: false,
    //   },
    // })
    let userData // set to response
    cookies.set('email', email, {path: '/'})
    cookies.set('password', password, {path: '/'})
    firestore.collection('LCDS') // Fetch userData
        .doc('Something')
        .collection('Physicians')
        .doc(firebase.auth().currentUser.uid)
        .onSnapshot((doc) => {
          if (doc.exists) {
            userData = doc.data()
            // console.log(firebase.auth().currentUser.uid, userData.Membership.Organization)
            dispatch(fetchOrgData(firestore, userData.Membership.Organization))
            /* Fetch organization patients */
            dispatch(fetchPatients(firestore, userData.Membership.Organization))
            dispatch({ // store response and signIn success
              type: types.SIGN_IN,
              payload: {
                // signedIn: true,
                // userMessage: null,
                authStatus: 'success',
                userData: userData,
              },
            })
            // dispatch({
            //   type: types.STORE_PAYLOAD,
            //   payload: {
            //     signedIn: true,
            //     // isLoading: false,
            //   },
            // })
            // dispatch({ // Clear notification
            //   type: types.STORE_PAYLOAD,
            //   payload: {
            //     userMessage: null,
            //     notificationType: null,
            //     notificationIcon: null,
            //   },
            // })
          } else {
            dispatch({ // store response and signIn success
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                userMessage: 'There was an error accessing your data. Please try again later.',
              },
            })
          }
        })
  }

  function signInFailureCB(response) {
    const isCookie = cookies && cookies.get('email') && cookies.get('password')
    if (isCookie) { // No warning if cookies are wrong (changed password)
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          isLoading: false,
          // userMessage: 'You entered an incorrect email, password, or both',
          // notificationType: 3,
          // notificationIcon: 'warning',
        },
      })
    } else {
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          isLoading: false,
          userMessage: 'You entered an incorrect email, password, or both',
          notificationType: 3,
          notificationIcon: 'warning',
        },
      })
    }
    dispatch({
      type: types.SIGN_IN,
      payload: {
        userMessage: 'You entered an incorrect email, password, or both',
        authStatus: 'failure',
        userData: null,
      },
    })
  }
}
